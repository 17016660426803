import translationEN from './locales/en/translations.json'
import translationSK from './locales/sk/translations.json'
import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

const resources = {
  en: {
    translation: translationEN
  },
  sk: {
    translation: translationSK
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "sk", //default language
    interpolation: {
      escapeValue: false,
    },
    keySeparator: '.',
    debug: true,
  });

export default i18n;
