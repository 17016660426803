import React, {useEffect} from 'react';

import {useTranslation} from 'react-i18next';
// import {Box, CircularProgress, useTheme} from "@mui/material";
import {Box} from "@mui/material";
// import {MenuItem} from "./interfaces/menu-item";
// import {useNavigate} from "react-router-dom";
import logo from "./resources/healthinel_zl_line.svg";

// function LoadingScreen() {
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         height: "100vh",
//       }}>
//       <Box sx={{width: "100%", textAlign: 'center'}}>
//         <CircularProgress size="30%"/>
//       </Box>
//     </Box>
//   );
// }

function App() {
  const {i18n, t} = useTranslation();
  // const navigate = useNavigate();
  //const theme = useTheme();

  //const [displayFullPageMenu, setDisplayFullPageMenu] = React.useState(false);

  // function navigateAndScroll(id: string) {
  //   navigate('/');
  //   setTimeout(() => {
  //     document.querySelector(`#${id}`)?.scrollIntoView({behavior: 'smooth'});
  //   }, 100);
  // }

  // const menuItems: MenuItem[] = [
  //   new MenuItem('pages.aboutUs.title', () => navigateAndScroll('AboutUs')),
  //   new MenuItem('pages.services.title', () => navigateAndScroll('Services')),
  //   new MenuItem('pages.carPark.title', () => navigateAndScroll('CarPark')),
  //   new MenuItem('pages.contact.title', () => navigateAndScroll('Contact')),
  // ];

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, [i18n]);

  return (
    <Box sx={{
      padding: 0,
      margin: 0,
      listStyle: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
    }}>
      <Box sx={{
        width: 'auto',
        textAlign: 'center',
      }}>
        <Box>
          <img src={logo} alt="Logo" style={{
            width: '60vw',
            display: 'inline-block',
          }}/>
          <br/>
          <br/>
          <span style={{fontWeight: '500', fontSize: '20px', textAlign: 'left', whiteSpace: 'pre-line'}}>{t('underConstruction')}</span>
        </Box>
      </Box>
    </Box>
  );

  /** return (
   <Suspense fallback={<LoadingScreen/>}>
   <FullPageMenu display={displayFullPageMenu} onClose={() => {
   console.log('set');
   setDisplayFullPageMenu(false)
   }} items={menuItems}/>
   <Box sx={{
   minHeight: '100vh',
   margin: 0,
   display: 'flex',
   flexDirection: 'column',
   }}>
   <Menu items={menuItems} openFullPageMenu={() => setDisplayFullPageMenu(true)}/>
   <Box sx={{
   flex: 1,
   }}>
   <Routes>
   <Route index element={<>
   <AboutUs id="AboutUs"/>
   <Box sx={{
   verticalAlign: 'middle',
   height: '250px',
   overflow: 'hidden',
   textAlign: 'center',
   backgroundColor: '#b6b6b6'
   }}>
   <img src={car} alt="Car" style={{
   height: '100%',
   display: 'inline-block',
   }}/>
   <img src={car} alt="Car" style={{
   height: '100%',
   display: 'inline-block',
   }}/>
   <img src={car} alt="Car" style={{
   height: '100%',
   display: 'inline-block',
   }}/>
   </Box>
   <AboutUs id="Services"/>
   </>}/>
   <Route path="/gdpr" element={"gdpr"}/>
   </Routes>
   </Box>
   <Footer/>
   </Box>
   </Suspense>
   ); */
}

export default App;
