import {createTheme} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2D2D2D",
      light: "#434343",
      dark: "#000000"
    },
    secondary: {
      main: "#0082ec",
      light: "#2E9FFC",
      dark: "#004883",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
        }
      }
    }
  },
  typography: {
    fontFamily: 'Poppins'
  },
}, {
  siteMaxWidth: "1100px",
});

export default theme;
